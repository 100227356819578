import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ServicesComponent } from './components/pages/services/services.component';
import { ServiceDetailsComponent } from './components/pages/service-details/service-details.component';
import { TeamComponent } from './components/pages/team/team.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';

import { BusinessStartupsComponent } from './components/pages/business-startups/business-startups.component';
import { SeoAgencyComponent } from './components/pages/seo-agency/seo-agency.component';


import { SeoLocalAstucesComponent } from './components/pages/blogs/seo-local-astuces/seo-local-astuces.component';
import { SeoSocialAdsComponent } from './components/pages/blogs/seo-social-ads/seo-social-ads.component';
import { ECommerceTendancesComponent } from './components/pages/blogs/e-commerce-tendances/e-commerce-tendances.component';


import { LogoAndPrintComponent } from './components/pages/expertises/logo-and-print/logo-and-print.component';
import { SiteWebEtEcommerceComponent } from './components/pages/expertises/site-web-et-ecommerce/site-web-et-ecommerce.component';
import { DeveloppementWebComponent } from './components/pages/expertises/developpement-web/developpement-web.component';
import { PubliciteSeoSeaComponent } from './components/pages/expertises/publicite-seo-sea/publicite-seo-sea.component';
import { MaintenanceComponent } from './components/pages/expertises/maintenance/maintenance.component';
import { HebergementComponent } from './components/pages/expertises/hebergement/hebergement.component';



const routes: Routes = [
    { path: '', component: BusinessStartupsComponent },
  
    { path: 'business-startups', component: BusinessStartupsComponent },
    { path: 'seo-agency', component: SeoAgencyComponent },
 
    { path: 'about', component: AboutComponent },
    { path: 'services', component: ServicesComponent },
    { path: 'service-details', component: ServiceDetailsComponent },
    { path: 'team', component: TeamComponent },
    { path: 'testimonials', component: TestimonialsComponent },
    { path: 'blog', component: BlogComponent },
    { path: 'blog-details', component: BlogDetailsComponent },

    { path: 'contact', component: ContactComponent },

    { path: 'blog/seo-local-astuces', component: SeoLocalAstucesComponent },
    { path: 'blog/seo-social-ads-facebook-instagram-snap', component: SeoSocialAdsComponent },
    { path: 'blog/e-commerce-tendances-strategies-technique-2024', component: ECommerceTendancesComponent },


    // Routes pour les expertises
    { path: 'expertises/logo-and-print', component: LogoAndPrintComponent },
    { path: 'logo-and-print', component: LogoAndPrintComponent },
    { path: 'expertises/site-web-et-ecommerce', component: SiteWebEtEcommerceComponent },
    { path: 'expertises/developpement-web', component: DeveloppementWebComponent },
    { path: 'expertises/publicite-seo-sea', component: PubliciteSeoSeaComponent },
    { path: 'expertises/maintenance', component: MaintenanceComponent },
    { path: 'expertises/hebergement', component: HebergementComponent },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

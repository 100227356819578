<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Services de Création de Sites Internet et E-commerce</h1>
        <ul>
            <li><a href="/">ACCUEIL</a></li>
            <li><a href="/services">SERVICES</a></li>
            <li>Création de Sites Internet et E-commerce</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="justified-text services-details-desc">
                        <h3>Création de Sites Internet et E-commerce</h3>
                        <p>Chez Neoluxis Digital, nous offrons des services complets de création de sites internet et e-commerce pour répondre aux besoins spécifiques de votre entreprise. Notre objectif est de fournir des solutions web innovantes et performantes, adaptées à vos objectifs et à votre marché cible.</p>
                        
                        <h3>Création de Sites Vitrines</h3>
                        <p>Un site vitrine est essentiel pour établir une présence en ligne et présenter votre entreprise au monde. Nos experts créent des sites vitrine qui reflètent parfaitement l'identité de votre marque, avec un design élégant et une navigation intuitive.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Design personnalisé</li>
                            <li><i class="flaticon-tick"></i> Optimisation mobile</li>
                            <li><i class="flaticon-tick"></i> Intégration de contenu multimédia</li>
                            <li><i class="flaticon-tick"></i> Hébergement sécurisé</li>
                        </ul>

                        <h3>Création de Sites E-commerce</h3>
                        <p>Nos solutions e-commerce sont conçues pour maximiser vos ventes en ligne. Nous développons des plateformes de commerce électronique robustes et conviviales, offrant une expérience utilisateur exceptionnelle qui encourage les achats et fidélise les clients.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Catalogues produits complets</li>
                            <li><i class="flaticon-tick"></i> Systèmes de paiement sécurisés</li>
                            <li><i class="flaticon-tick"></i> Gestion des stocks en temps réel</li>
                            <li><i class="flaticon-tick"></i> Fonctionnalités de marketing intégrées</li>
                        </ul>

                        <h3>Création de Landing Pages de Vente</h3>
                        <p>Les landing pages sont cruciales pour les campagnes marketing et de conversion. Nous créons des pages de destination percutantes qui captivent les visiteurs et les incitent à passer à l'action, que ce soit pour acheter un produit, s'inscrire à une newsletter ou télécharger un ebook.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Appels à l'action efficaces</li>
                            <li><i class="flaticon-tick"></i> Formulaires de capture de leads optimisés</li>
                            <li><i class="flaticon-tick"></i> A/B testing pour améliorer les conversions</li>
                            <li><i class="flaticon-tick"></i> Intégration avec des outils de marketing</li>
                        </ul>
                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/site-internet.jpg" alt="Création de Sites Internet et E-commerce">
                            </div>
                            <div class="item">
                                <img src="assets/img/ecommerce.jpg" alt="Solutions E-commerce">
                            </div>
                            <div class="item">
                                <img src="assets/img/produits.jpg" alt="Développement Web">
                            </div>
                            <div class="item">
                                <img src="assets/img/immobilier.jpg" alt="Développement Web">
                            </div>
                        </div>

                        <h3>Pourquoi Choisir Nos Services</h3>
                        <p>Nos services de création de sites internet et e-commerce se distinguent par :</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Design personnalisé et moderne</li>
                            <li><i class="flaticon-tick"></i> Optimisation SEO intégrée</li>
                            <li><i class="flaticon-tick"></i> Solutions de paiement sécurisées</li>
                            <li><i class="flaticon-tick"></i> Hébergement fiable et évolutif</li>
                            <li><i class="flaticon-tick"></i> Support technique 24/7</li>
                        </ul>

                        <blockquote class="blockquote">
                            <p>"La réussite de votre entreprise passe par un site web performant et une boutique en ligne attrayante. Faites confiance à Neoluxis Digital pour transformer vos idées en réalité digitale."</p>
                        </blockquote>

                        <h3>Les Bénéfices de Nos Services</h3>
                        <p>En choisissant nos services, vous bénéficiez d'une infrastructure de pointe, d'une sécurité renforcée et d'un support technique expert. Nous travaillons avec des partenaires cloud tels que AWS, Azure, Google Cloud, et OVH pour garantir que votre site est toujours disponible et performant, quelles que soient les circonstances.</p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quels types de sites pouvez-vous créer ?
                                    </a>
                                    <p class="accordion-content show">Nous créons des sites vitrines, des sites e-commerce, des pages de vente, et des sites institutionnels, adaptés à tous les secteurs d'activité.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Utilisez-vous des plateformes CMS ?
                                    </a>
                                    <p class="accordion-content">Oui, nous utilisons des plateformes CMS comme WordPress pour faciliter la gestion de votre contenu. Pour les solutions e-commerce, nous intégrons WooCommerce et Shopify.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Offrez-vous des services de landing page pour les influenceurs ?
                                    </a>
                                    <p class="accordion-content">Absolument, nous créons des landing pages personnalisées pour les influenceurs, optimisées pour les conversions et le marketing d'affiliation.</p>
                                </li>
                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Pouvez-vous gérer les noms de domaine ?
                                    </a>
                                    <p class="accordion-content">Oui, nous offrons des services complets de gestion de noms de domaine, incluant l'achat, le transfert et le renouvellement.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <div class="widget service_list">
                        <ul>
                            <li><a routerLink="/expertises/site-web-et-ecommerce" class="active">Création de Sites Internet et E-commerce <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/developpement-web">Développement Web <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/publicite-seo-sea">SEO & SEA <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/hebergement">Hébergement <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/maintenance">Maintenance <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/logo-and-print">Logo & Print <i class="flaticon-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="widget widget_download">
                        <h3 class="widget-title">Download</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a routerLink="#">Guide des Stratégies de Marketing Digital Efficaces<i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Complet d'Optimisation SEO & SEA <i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Avancé de Développement Web & Maintenance <i class="flaticon-edit"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->

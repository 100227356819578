<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Développement Web</h1>
        <ul>
            <li><a routerLink="/">ACCUEIL</a></li>
            <li><a href="/services">SERVICES</a></li>
            <li>Développement Web</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="justified-text services-details-desc">
                        <h3>Développement Web</h3>
                        <p>Chez Neoluxis Digital, nous offrons des services de développement web spécialisés dans la création d'applications métiers performantes et adaptées aux besoins spécifiques de votre entreprise. Notre expertise couvre des technologies modernes telles que :</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Java EE</li>
                            <li><i class="flaticon-tick"></i> Angular</li>
                            <li><i class="flaticon-tick"></i> React</li>
                            <li><i class="flaticon-tick"></i> HTML, CSS et JavaScript</li>
                        </ul>

                        <h3>Exemples d'Applications Métiers</h3>
                        <p>Nous développons une large gamme d'applications métiers pour répondre aux besoins diversifiés des entreprises. Voici quelques exemples :</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Systèmes de gestion des ressources humaines (HRM)</li>
                            <li><i class="flaticon-tick"></i> Logiciels de gestion de la relation client (CRM)</li>
                            <li><i class="flaticon-tick"></i> Plateformes de commerce électronique personnalisées</li>
                            <li><i class="flaticon-tick"></i> Applications de gestion de la chaîne d'approvisionnement</li>
                            <li><i class="flaticon-tick"></i> Systèmes de gestion des stocks</li>
                        </ul>

                        <div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/dev-web1.jpg" alt="Développement Angular">
                            </div>
                          
                            <div class="item">
                                <img src="assets/img/dev-web3.jpg" alt="Développement Java EE">
                            </div>
                        </div>

                        <h3>Pourquoi Choisir Nos Services</h3>
                        <p>En choisissant Neoluxis Digital, vous bénéficiez de l'expertise de notre équipe de développeurs expérimentés et passionnés. Nous travaillons en étroite collaboration avec vous pour comprendre vos besoins et fournir des solutions personnalisées qui répondent à vos exigences spécifiques.</p>

                        <blockquote class="blockquote">
                            <p>"Transformez vos idées en applications web performantes et évolutives grâce à Neoluxis Digital."</p>
                        </blockquote>

                        <h3>Les Bénéfices de Nos Services</h3>
                        <p>Nos services de développement web offrent une valeur ajoutée en garantissant des solutions performantes, sécurisées et évolutives. Nous nous assurons que chaque application développée répond aux normes les plus élevées en matière de qualité et de performance.</p>
                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Solutions sur mesure</li>
                            <li><i class="flaticon-tick"></i> Technologies modernes</li>
                            <li><i class="flaticon-tick"></i> Performance et sécurité</li>
                            <li><i class="flaticon-tick"></i> Support technique continu</li>
                        </ul>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Pourquoi choisir Angular pour le développement d'applications ?
                                    </a>
                                    <p class="accordion-content show">Angular offre une structure solide pour le développement d'applications web complexes, avec des fonctionnalités intégrées pour la gestion des données, la sécurité et la performance.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quels sont les avantages de React pour le développement web ?
                                    </a>
                                    <p class="accordion-content">React permet de créer des interfaces utilisateur rapides et dynamiques, avec une architecture modulaire qui facilite la maintenance et l'évolution des applications.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Pourquoi utiliser Java EE pour les applications d'entreprise ?
                                    </a>
                                    <p class="accordion-content">Java EE offre une plateforme robuste et sécurisée pour le développement d'applications d'entreprise, avec des fonctionnalités avancées pour la gestion des transactions et l'intégration d'applications.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quel est le processus de développement chez Neoluxis Digital ?
                                    </a>
                                    <p class="accordion-content">Nous suivons un processus de développement agile, avec des phases de planification, de développement, de test et de déploiement, en étroite collaboration avec nos clients pour garantir que chaque projet répond à leurs besoins et attentes.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Search here...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <div class="widget service_list">
                        <ul>
                            <li><a routerLink="/expertises/developpement-web" class="active">Développement Web <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/site-web-et-ecommerce">Création de Sites Internet et E-commerce <i class="flaticon-right"></i></a></li>
                            
                            <li><a routerLink="/expertises/publicite-seo-sea">Publicité SEO & SEA <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/hebergement">Hébergement <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/maintenance">Maintenance <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/logo-and-print">Logo & Print <i class="flaticon-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="widget widget_download">
                        <h3 class="widget-title">Téléchargements</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a routerLink="#">Guide des Stratégies de Marketing Digital Efficaces<i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Complet d'Optimisation SEO & SEA <i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Avancé de Développement Web & Maintenance <i class="flaticon-edit"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->

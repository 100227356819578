<!-- Start Page Title Area -->
<section class="page-title-area uk-page-title">
    <div class="uk-container">
        <h1>Maintenance</h1>
        <ul>
            <li><a routerLink="/">Accueil</a></li>
            <li><a href="/services">SERVICES</a></li>
            <li>Maintenance</li>
        </ul>
    </div>
</section>
<!-- End Page Title Area -->

<!-- Start Services Details Area -->
<section class="services-details-area uk-services-details uk-section">
    <div class="uk-container">
        <article class="uk-article services-details">
            <div class="uk-grid uk-flex">
                <div class="inner uk-width-expand">
                    <div class="justified-text services-details-desc">
                        <h3>Maintenance</h3>
                        <p>Chez Neoluxis Digital, nous comprenons l'importance de maintenir vos systèmes web en parfait état de fonctionnement. Nos services de maintenance vous assurent que votre site reste à jour, sécurisé et performant.</p>

                        <p>Nos experts en maintenance technique veillent à ce que toutes les mises à jour nécessaires soient effectuées régulièrement, que les bugs soient corrigés rapidement, et que vos données soient sauvegardées et sécurisées en permanence. Nous vous offrons une tranquillité d'esprit totale pour que vous puissiez vous concentrer sur la croissance de votre entreprise.</p>

                        <!--<div class="services-image-slides owl-carousel owl-theme">
                            <div class="item">
                                <img src="assets/img/maintenance1.jpg" alt="Maintenance Image">
                            </div>

                            <div class="item">
                                <img src="assets/img/maintenance2.jpg" alt="Maintenance Image">
                            </div>

                            <div class="item">
                                <img src="assets/img/maintenance3.jpg" alt="Maintenance Image">
                            </div>
                        </div>-->

                        <h3>Pourquoi Choisir Ce Service</h3>
                        <p>Nous offrons des services de maintenance de qualité supérieure, adaptés aux besoins spécifiques de chaque client. Notre équipe de spécialistes est disponible 24/7 pour répondre à toutes vos préoccupations et garantir le bon fonctionnement de vos systèmes.</p>

                        <ul class="services-features-list">
                            <li><i class="flaticon-tick"></i> Mises à jour régulières</li>
                            <li><i class="flaticon-tick"></i> Sécurité renforcée</li>
                            <li><i class="flaticon-tick"></i> Sauvegardes automatiques</li>
                            <li><i class="flaticon-tick"></i> Correction rapide des bugs</li>
                            <li><i class="flaticon-tick"></i> Support client 24/7</li>
                        </ul>

                        <blockquote class="blockquote">
                            <p>Notre priorité est de garantir que votre site web fonctionne de manière optimale à tout moment. Nous sommes dédiés à fournir un service fiable et réactif pour répondre à toutes vos attentes.</p>
                        </blockquote>

                        <h3>Les Bénéfices de Nos Services</h3>
                        <p>En choisissant nos services de maintenance, vous bénéficiez de la tranquillité d'esprit sachant que vos systèmes sont entre de bonnes mains. Nous assurons une performance constante et une sécurité renforcée pour votre site web.</p>

                        <div class="our-work-benefits">
                            <ul class="accordion">
                                <li class="accordion-item">
                                    <a class="accordion-title active" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quels types de mises à jour fournissez-vous ?
                                    </a>

                                    <p class="accordion-content show">Nous fournissons des mises à jour régulières pour tous les aspects de votre site web, y compris les mises à jour de sécurité, les mises à jour de fonctionnalités et les correctifs de bugs.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Offrez-vous des sauvegardes automatiques ?
                                    </a>

                                    <p class="accordion-content">Oui, nous offrons des sauvegardes automatiques régulières pour garantir que vos données sont toujours protégées et peuvent être restaurées en cas de besoin.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Quels types de support client proposez-vous ?
                                    </a>

                                    <p class="accordion-content">Notre support client est disponible 24/7 pour répondre à toutes vos questions et résoudre tous les problèmes techniques que vous pourriez rencontrer.</p>
                                </li>

                                <li class="accordion-item">
                                    <a class="accordion-title" href="javascript:void(0)">
                                        <i class="flaticon-plus"></i>
                                        Proposez-vous des services de sécurité ?
                                    </a>

                                    <p class="accordion-content">Oui, nous renforçons la sécurité de votre site web avec des mesures proactives pour protéger vos données et prévenir les menaces potentielles.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="uk-sidebar uk-width-1-5 uk-flex-first@l uk-first-column">
                    <div class="widget widget_search">
                        <form>
                            <input type="text" class="uk-input" placeholder="Rechercher...">
                            <button type="submit"><i class="flaticon-search"></i></button>
                        </form>
                    </div>

                    <div class="widget service_list">
                        <ul>
                            <li><a routerLink="/expertises/maintenance" class="active">Maintenance <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/site-web-et-ecommerce">Création de Sites Internet <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/developpement-web">Développement Web <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/publicite-seo-sea">SEO & SEA <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/hebergement">Hébergement <i class="flaticon-right"></i></a></li>
                            <li><a routerLink="/expertises/logo-and-print">Logo & Print <i class="flaticon-right"></i></a></li>
                        </ul>
                    </div>

                    <div class="widget widget_download">
                        <h3 class="widget-title">Téléchargements</h3>
                        <div class="bar"></div>

                        <ul>
                            <li><a routerLink="#">Guide des Stratégies de Marketing Digital Efficaces<i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Complet d'Optimisation SEO & SEA <i class="flaticon-edit"></i></a></li>
                            <li><a routerLink="#">Manuel Avancé de Développement Web & Maintenance <i class="flaticon-edit"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </article>
    </div>
</section>
<!-- End Services Details Area -->

<!-- Start Mobile Navbar -->
<!-- Start Mobile Navbar -->
<div id="offcanvas-flip" class="mobile-navbar uk-mobile-navbar" uk-offcanvas="flip: true; overlay: true">
    <div class="uk-offcanvas-bar">

        <button class="uk-offcanvas-close" type="button" uk-close></button>

        <nav class="uk-navbar-container" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
            <ul class="uk-navbar-nav">
                
                <li>
                    <a routerLink="/" routerLinkActive="uk-active" (click)="closeMenu()">Accueil</a>
                </li>
                <li>
                    <a routerLink="/about" routerLinkActive="uk-active" (click)="closeMenu()">L'agence</a>
                </li>
                <li>
                    <a routerLink="/services" routerLinkActive="uk-active" (click)="closeMenu()">Nos expertises</a>
                </li>

                <li>
                    <a routerLink="/contact" routerLinkActive="uk-active" (click)="closeMenu()">Contact</a>
                </li>
               
                <li>
                    <a routerLink="/blog" routerLinkActive="uk-active" (click)="closeMenu()">Blog</a>
                </li>
               
            </ul>
        </nav>
    </div>
</div>
<!-- End Mobile Navbar -->

<!-- End Mobile Navbar -->

<!-- Start Navbar Area -->
<header class="header-area {{navClass}}" data-uk-sticky="top: 0; animation: uk-animation-slide-top;">
    <div class="uk-container">
        <div class="uk-navbar">
            <div class="logo uk-navbar-left">
                <a routerLink="/">
                    <img src="assets/img/{{logo}}" alt="logo">
                </a>
            </div>

            <div class="uk-navbar-toggle" id="navbar-toggle" uk-toggle="target: #offcanvas-flip">
                <span></span>
                <span></span>
                <span></span>
            </div>

            <div class="navbar uk-navbar-right">
                <nav class="uk-navbar-container" data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true">
                    <ul class="uk-navbar-nav">
                
                        <li>
                            <a routerLink="/" routerLinkActive="uk-active">Accueil</a>
                        </li>
                        <li>
                            <a routerLink="/about" routerLinkActive="uk-active">L'agence</a>
                        </li>
                        <li>
                            <a routerLink="/services" routerLinkActive="uk-active">Nos expertises</a>
                        </li>
        
                     
                        <li>
                            <a routerLink="/contact" routerLinkActive="uk-active">Contact</a>
                        </li>
                       
                        <li>
                            <a routerLink="/blog" routerLinkActive="uk-active">Blog</a>
                        </li>
                       
                    </ul>
                </nav>

                
            </div>
        </div>
    </div>
</header>
<!-- End Navbar Area -->